import { get } from 'lodash';
import { merge } from 'lodash/fp';

import { ACTION_TYPES } from '@marketmuse/data-state';
import {
  sanitizeOrgSlug,
  serializeAccessibleInventories,
} from '@marketmuse/utilities';

import * as types from '../config/types';
import initialState from '../config/initialState';

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case types.SET_USER_IDENTITY:
      return {
        ...action.user,
        org: {
          ...(action.user?.org || {}),
          slug: sanitizeOrgSlug(action.user?.org?.slug),
          sites: serializeAccessibleInventories(action.user?.org?.sites),
        },
      };

    case types.SAVE_USER_DATA:
      return { ...action.user };

    case ACTION_TYPES.SIGN_OUT:
      return { ...initialState.user };

    case ACTION_TYPES.UPDATE_USER_DATA_STATE:
      const data = action.payload || {};
      return { ...state, ...data };

    case ACTION_TYPES.ORG_DATA_SAVE:
      const nextSites = serializeAccessibleInventories(action.payload.sites);
      return {
        ...state,
        org: merge(state.org, {
          ...action.payload,
          sites: merge(state.org.sites, nextSites),
        }),
      };

    case ACTION_TYPES.ORG_DATA_SITE_DELETE:
      return {
        ...state,
        org: {
          ...state.org,
          sites: state.org.sites.filter(
            site => site.id !== action.payload.siteId,
          ),
        },
      };

    case ACTION_TYPES.INVENTORY_FIELDS_UPDATE:
      const sites = get(state, 'org.sites', []);
      const siteIndex =
        sites && sites.map(s => s.id).indexOf(action.payload.siteId);
      const sitesNew = sites && [...sites];

      if (siteIndex === -1) {
        return state;
      }

      sitesNew[siteIndex] = {
        ...sitesNew[siteIndex],
        ...action.payload.updatedFields,
      };

      return {
        ...state,
        org: {
          ...state.org,
          sites: sitesNew,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
