import { ACTION_TYPES } from '@marketmuse/data-state';
import * as types from '../config/types';

export const selectSite = ({
  id,
  resetApps,
  skipFetchPermissions = false,
}) => ({
  type: ACTION_TYPES.ACTIVE_INVENTORY_SELECT,
  id,
  resetApps,
  skipFetchPermissions,
});

export const setActiveInventory = site => ({
  type: ACTION_TYPES.ACTIVE_INVENTORY_SET,
  site,
});

export const getBriefStats = () => ({
  type: types.GET_BRIEF_STATS,
});

export const getPlanStats = ({ siteId }) => ({
  type: types.GET_PLANS_STATS,
  siteId,
});

export const getAggregateStats = ({ siteId }) => ({
  type: ACTION_TYPES.AGGREGATE_STATS_GET,
  payload: siteId,
});

export const setAggregateStats = ({ data }) => ({
  type: ACTION_TYPES.AGGREGATE_STATS_SET,
  data,
});

export const getSitesByOrg = ({ id, callback }) => ({
  type: types.GET_SITES_BY_ORG,
  id,
  callback,
});
