import get from 'lodash/get';
import { ACTION_TYPES } from '@marketmuse/data-state';

import * as types from '../config/types';
import initialState from '../config/initialState';

const filterReducer = (state = initialState.filter, action) => {
  switch (action.type) {
    case types.SET_USER_IDENTITY:
    case ACTION_TYPES.ACTIVE_INVENTORY_SET:
      return { ...state, site: { ...action.site } };

    case ACTION_TYPES.INVENTORY_VERSIONS_SAVE:
      return { ...state, versions: [...action.payload] };

    case ACTION_TYPES.SIGN_OUT:
      return {
        ...state,
        site: initialState.filter.site,
        versions: initialState.filter.versions,
      };

    case types.UPDATE_CREDITS:
      return {
        ...state,
        site: {
          ...state.site,
          ...(action.briefCredits && { briefCredits: action.briefCredits }),
        },
      };

    case ACTION_TYPES.INVENTORY_FIELDS_UPDATE:
      if (get(state, 'site.id') !== action.payload.siteId) {
        return state;
      }

      return {
        ...state,
        site: {
          ...state.site,
          ...action.payload.updatedFields,
        },
      };

    default:
      return state;
  }
};

export default filterReducer;
