import { useEffect, useMemo } from 'react';
import { useUser } from '@clerk/clerk-react';
import { initTrackedUser, trackerReset } from '../../utils/tracker';
import useSessionData from './useSessionData';
import { IdentityContext } from './context';

const IdentityProvider = ({ children }) => {
  const sessionId = useSessionData();

  const { isLoaded, isSignedIn, user } = useUser();

  const context = useMemo(
    () => ({
      sessionId,
      isLoading: isLoaded === null,
      isSignedIn,
      user,
    }),
    [sessionId, isLoaded, isSignedIn, user],
  );

  useEffect(() => {
    if (isLoaded && isSignedIn && user) {
      initTrackedUser({
        user: {
          id: user.id,
          fullName: user.fullName,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.emailAddresses?.[0].emailAddress,
        },
        site: null,
        subscriptions: null,
      });
    }

    if (!user) {
      trackerReset();
    }
  }, [user, isSignedIn, isLoaded]);

  return (
    <IdentityContext.Provider value={context}>
      {children}
    </IdentityContext.Provider>
  );
};

export default IdentityProvider;
