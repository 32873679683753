import get from 'lodash/get';

import { PlanDeleteDocument } from '@marketmuse/data-papi';
import { plansActionTypes } from '@marketmuse/data-state/plans';

import makeRequest from '../../utils/makeRequest';

import { plansDeleted } from '../../actions/planActions';
import { setSimple } from '../../actions/miscActions';
import { getAggregateStats } from '../../actions/siteActions';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === plansActionTypes.PLANS_DELETE) {
      makeRequest({
        type: plansActionTypes.PLANS_DELETE,
        mutation: PlanDeleteDocument,
        variables: {
          planId: action.planIds,
        },
      }).then(res => {
        if (action.callback) action.callback();
        dispatch(setSimple({ key: 'tableViewRefetchTime', value: new Date() }));
        dispatch(getAggregateStats({ siteId: get(state, 'filter.site.id') }));
        dispatch(
          plansDeleted({
            siteId: get(state, 'filter.site.id'),
            planIds: action.planIds,
          }),
        );
      });
    }

    return next(action);
  };
