import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PAPI } from '@marketmuse/config/types';
import { miscActions } from '@marketmuse/data-state/misc';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';

import * as miscActionsList from '../../actions/miscActions';
import isAdminMode from '../../utils/isAdminMode';
import isDeveloperMode from '../../utils/isDeveloperMode';

import useIdentitySuite from '../../hooks/auth/useIdentitySuite';
import useHasAccess from '../../hooks/useHasAccess';
import IconSvg from '../../components/IconSvg/IconSvg';
import Button from '../../components/Button';
import withTailwindCompatibility from '../../hocs/withTailwindCompatibility';

import AdminSidebarScreen from './screens/AdminSidebarScreen';
import BriefingSidebarScreen from './screens/BriefingSidebarScreen';
import MainSidebarScreen from './screens/MainSidebarScreen';
import PlanningSidebarScreen from './screens/PlanningSidebarScreen';

import SidebarItem from './components/SidebarItem';
import UserProfile from './components/UserProfile';
import ConsumptionBlockBase from './components/ConsumptionBlock';

const ConsumptionBlock = withTailwindCompatibility({
  Component: ConsumptionBlockBase,
});

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: ${p =>
    p.theme?.[p.collapsed ? 'sidebarWidthSmall' : 'sidebarWidthLarge']}px;
  height: 100%;
  padding-top: 0;
  ${p =>
    p.collapsed &&
    `
    padding-top: 22px;
    padding-bottom: 12px;
  `}
  color: ${p => p.theme.colors.grey25};
  background-color: ${p => p.theme.colors.grey85};
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  will-change: transform;
  transform: translateZ(0);
  overflow: auto;
`;

const Content = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  position: sticky;
  bottom: 0;
  left: 0;
  padding-top: ${p => p.theme.spacing.small};
  background-color: ${p => p.theme.colors.grey85};
`;

const FooterItemWrapper = styled.div`
  background-color: ${p => p.theme.colors.grey85};
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  position: sticky;
  bottom: 0;
  left: 0;
  padding-top: ${p => p.theme.spacing.small};
  padding-right: ${p => p.theme.spacing.medium};
  padding-left: ${p => p.theme.spacing.medium};
`;

const Sidebar = props => {
  const { pathname: historyPathname } = useLocation();
  const dispatch = useDispatch();
  const [activeScreen, setActiveScreen] = useState('main');
  const { userId, orgId } = useIdentitySuite();

  const admin = useSelector(state => state.admin);
  const persist = useSelector(state => state.persist);

  const hasDocumentsAccess = useHasAccess({
    resource: PAPI.PermissionResource.DOCUMENTS,
    action: PAPI.PermissionAction.ACCESS_DATA,
  });

  const invAllAccess = useHasAccess({
    resource: PAPI.PermissionResource.SITE_INVENTORY,
    action: PAPI.PermissionAction.ACCESS_DATA,
  });

  const isDeveloper = isDeveloperMode();
  const isAdmin = isAdminMode(admin, { id: userId, org: { id: orgId } });
  const collapsed = persist?.sidebarCollapsed || false;

  const screenProps = {
    isAdmin,
    collapsed,
    labelLoading: props.stageCountsLoading,
    setActiveScreen,
    historyPathname,
    activeScreen,
    canOrderItems: false,
    invAllAccess,
    isDeveloper,
  };

  return (
    <Wrapper collapsed={collapsed}>
      <Content>
        {/* user profile */}
        {!collapsed && <UserProfile />}
        {collapsed && (
          <>
            <SidebarItem
              collapsed={collapsed}
              icon={<IconSvg name="chevron-right" size="16" />}
              onClick={() => dispatch(miscActionsList.sidebarToggle())}
            />
            {isAdmin && (
              <SidebarItem
                collapsed={collapsed}
                adminMode
                title="Admin Mode"
                onClick={() => dispatch(miscActionsList.sidebarToggle())}
              />
            )}
            <SidebarItem
              collapsed={collapsed}
              icon={<IconSvg name="avatar" size="28" box="26" />}
              onClick={() => dispatch(miscActionsList.sidebarToggle())}
            />
          </>
        )}
        {activeScreen === 'main' && (
          <MainSidebarScreen screenId="main" {...screenProps} />
        )}
        {activeScreen === 'admin' && (
          <AdminSidebarScreen screenId="admin" {...screenProps} />
        )}
        {activeScreen === 'planning' && (
          <PlanningSidebarScreen screenId="planning" {...screenProps} />
        )}
        {activeScreen === 'briefing' && (
          <BriefingSidebarScreen screenId="briefing" {...screenProps} />
        )}
      </Content>

      <FooterWrapper>
        {hasDocumentsAccess && (
          <FooterItemWrapper>
            <Button
              primary
              large
              data-track="sidebar--documents-create--wrapper"
              onClick={() => {
                dispatch(
                  miscActions.setSimple({
                    key: MISC_SIMPLE_KEYS.documentsCreate,
                    value: { isVisible: true },
                  }),
                );
              }}
              style={{ width: '100%' }}
            >
              + Create Document
            </Button>
          </FooterItemWrapper>
        )}

        <ConsumptionBlock />
      </FooterWrapper>
    </Wrapper>
  );
};

export default Sidebar;
